import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import "pure-react-carousel/dist/react-carousel.es.css"
import SEO from "../components/seo"
import ActualiteGrid from "../components/ActualiteGrid"

const ActualitesTags = props => {
  const { data } = props

  const { edges: actualites } = data.allNodeActualites

  const { id, title } = props.pageContext

  return (
    <Layout>

      <SEO title={`Actualité voiture neuve au maroc ${title}`} description={`${title} toute l'actualite des voitures neuves au maroc, prix et fiche techniques des voitures neuf au maroc ${title}`}/>

      <div className="section section bg-dark text-white overflow-hidden z-2">
        <div className="container z-2">
          <div className="row">
            <div className="col-12 col-lg-10">
            </div>
          </div>

        </div>
      </div>


      <section className="section section-lg mb-3 p-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-1">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                    <li className="breadcrumb-item">
                      <Link
                        to={`/`}>
                        Accueil
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link
                        to={`/actualites`}>
                        Actualités
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-lg mb-1 p-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="display-3">
                Actualité des voitures neuves {title}</h1>
              <ActualiteGrid title="" actualites={actualites}/>

            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default ActualitesTags

export const pageQuery = graphql`
  query ActualitesTags($title: String!) {
     allNodeActualites(filter: {relationships: {field_tags: {elemMatch: {name: {eq: $title}}}}}) {
    edges {
      node {
        id
        title
        path {
          alias
        }
        field_description
                relationships {
                field_tags {
        name
              path {
          alias
        }
      }
          field_image {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  }
  }
`
