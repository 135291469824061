import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default class IndexPage extends React.Component {
  render() {
    const { actualites, title, withHeader } = this.props
    return (
      <div className="row">
        {withHeader ? (
          <div className="col-lg-12 mb-5">
            <div className="card bg-white border-light flex-lg-row align-items-center no-gutters p-4">
              <Link
                className="col-12 col-lg-6"
                to={`${actualites[0].node.path.alias}/`}
              >
                <Img
                  className="card-img-top rounded"
                  fluid={
                    actualites[0].node.relationships.field_image.localFile
                      .childImageSharp.fluid
                  }
                  alt={`${actualites[0].node.title}`}
                />
              </Link>

              <div className="card-body d-flex flex-column justify-content-between col-auto py-4 p-lg-3 p-xl-5">
                <Link to={`${actualites[0].node.path.alias}/`}>
                  <h2>{actualites[0].node.title}</h2>
                </Link>
                <p>{actualites[0].node.field_description}</p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {actualites.slice(1).map(actualite => (
          <div
            key={actualite.node.id}
            className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5"
          >
            <div className="card bg-white border-light p-3 rounded">
              <Link to={`${actualite.node.path.alias}/`}>
                <Img
                  className="card-img-top rounded"
                  fluid={
                    actualite.node.relationships.field_image.localFile
                      .childImageSharp.fluid
                  }
                  alt={`${actualite.node.title}`}
                />
              </Link>
              <div className="card-body p-0 pt-4">
                <Link className="h4" to={`${actualite.node.path.alias}/`}>
                  {actualite.node.title}
                </Link>
                <p className="mb-0">{actualite.node.field_description}</p>
                {actualite.node.relationships.field_tags ? (
                  <div className="wi-tags">
                    {actualite.node.relationships.field_tags.map(tag => (
                      <a
                        title={tag.name}
                        key={tag.name}
                        href={`/categorie${tag.path.alias}/`}
                      >
                        {tag.name}
                      </a>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

IndexPage.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}
